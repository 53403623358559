/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import styles from "./styles.module.css";

const Main = () => {
	
	return (
	<>	
<div className={styles.s1}>
  <div>
  <div className="border rounded border-0 d-flex flex-column justify-content-center align-items-center p-4 py-5" style={{background: 'linear-gradient(rgba(0,123,255,0.2), rgba(0,123,255,0.2)), url("assets/img/IMG_2628.webp") center / cover', height: 'inherit'}}>
    <div className="row" style={{marginLeft: 0, marginRight: 0, paddingTop: 0}}>
      <div className="col-md-12 col-lg-12 col-xl-12 col-xxl-12 text-center d-flex d-sm-flex d-md-flex justify-content-center align-items-center mx-auto justify-content-md-start align-items-md-center justify-content-xl-center" style={{marginTop: 152}}>
        <div>
          <h1 className="text-uppercase fw-bold mb-3" style={{color: 'var(--bs-dark-bg-subtle)', fontFamily: '"Abril Fatface", serif', paddingTop: 15, marginTop: '-10px'}}><br /><br /><strong><span style={{color: 'rgb(var(--color_11))', backgroundColor: 'transparent'}}>THE&nbsp; PLACEMENT&nbsp; CELL</span></strong><br /><br /><br /><strong><span style={{color: 'rgb(255, 255, 255)'}}>MOTILAL&nbsp; &nbsp;NEHRU&nbsp; COLLEGE&nbsp;</span></strong><br /><strong><span style={{color: 'rgb(255, 255, 255)'}}>UNIVERSITY&nbsp; OF&nbsp; DELHI</span></strong><br /><br /></h1>
        </div>
      </div>
    </div>
  </div>
  <div className="container py-4 py-xl-5">
    <div className="row gy-4 gy-md-0">
      <div className="col-md-6" style={{marginRight: '-4px', width: 1140}}>
        <div className="p-xl-5 m-xl-5" style={{paddingTop: 48}}><img className="rounded img-fluid w-100 fit-cover" data-aos="fade-up" data-aos-duration={350} style={{minHeight: 300, height: 477, paddingRight: 0, marginRight: 0}} src="assets/img/About-1.webp" width={450} height={477} /></div>
      </div>
    </div><img src="assets/img/123.png" />
  </div>
</div>

<div className="container py-4 py-xl-5">
        <div className="row mb-4 mb-lg-5">
          <div className="col-md-8 col-xl-6 text-center mx-auto">
            <h2
              style={{ fontSize: '44.112px', color: 'rgb(0,0,0)', fontWeight: 'bold' }}
            >
              Contact Our Team
            </h2>
          </div>
        </div>

        <div className="row gy-4 row-cols-2 row-cols-md-4 justify-content-center">
  {teamMembers.map((member, index) => (
    <div
      className={`col ${member.position === 'President' ? 'w-100' : ''}`}
      key={member.name}
      style={member.position === 'President' ? { textAlign: 'center' } : {}}
    >
      <div className="card border-0 shadow-none">
        <div className="card-body text-center d-flex flex-column align-items-center p-0">
          <img
            className="rounded-circle mb-3 fit-cover"
            data-bss-hover-animate="pulse"
            width={130}
            height={130}
            src={member.imageSrc}
            alt={member.name}
            style={{
              width: member.position === 'President' ? 180 : 160,
              height: member.position === 'President' ? 180 : 160,
            }}
          />
          <h5 className="fw-bold text-primary card-title mb-0">
            <strong>{member.name}</strong>
          </h5>
          <p className="text-muted card-text mb-2">{member.position}</p>
          <div className="col">
            <a href={`tel:${member.phone}`}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                fill="currentColor"
                viewBox="0 0 16 16"
                className="bi bi-telephone-fill"
                style={{
                  paddingRight: 6,
                  marginRight: 7,
                  fontSize: 21,
                }}
              >
                <path
                  fillRule="evenodd"
                  d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
                />
              </svg>
            </a>
            <a href={`mailto:${member.email}`}>
              <i
                className="fa fa-envelope"
                style={{
                  fontSize: 16,
                  marginTop: '-10px',
                  marginBottom: 0,
                }}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  ))}
</div>
</div>
  <div className="container py-4 py-xl-5">
    <div className="row mb-5">
      <div className="col-md-8 col-xl-6 text-center mx-auto">
        <h2 style={{fontSize: '61.112px', color: 'rgb(0,0,0)', fontWeight: 'bold'}}>Are you a Recruiter?</h2>
      </div>
    </div>
    <div className="row gy-4 row-cols-1 row-cols-md-2 row-cols-xl-3">
    <div className="col">
  <div className="text-center d-flex flex-column align-items-center align-items-xl-center">
    <div className="bs-icon-lg bs-icon-rounded bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-3 bs-icon lg" style={{ fontSize: 21, width: 60, height: 60 }}>
      <a href="tel:9599301936">
        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" className="bi bi-telephone-fill">
          <path fillRule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
        </svg>
      </a>
    </div>
    <div className="px-3">
      <h4 style={{ fontWeight: 'bold' }}>Call Us</h4>
    </div>
  </div>
</div>

      <div className="col">
  <div className="text-center d-flex flex-column align-items-center align-items-xl-center">
    <div className="bs-icon-lg bs-icon-rounded bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-3 bs-icon lg" style={{ width: 60, height: 60 }}>
      <a className="reauth-email" href="mailto:placementcell@mln.du.ac.in">
        <i className="fa fa-envelope" style={{ fontSize: 25, bsBodyBg: 'var(--bs-card-bg)', paddingRight: 0, marginTop: 0, marginRight: 0, color: '#eeeeee' }} />
      </a>
    </div>
    <div className="px-3">
      <h4 style={{ fontWeight: 'bold' }}>Mail Us</h4>
    </div>
  </div>
</div>

<div className="col">
  <div className="text-center d-flex flex-column align-items-center align-items-xl-center">
    <a href="/about"> 
      <div className="bs-icon-lg bs-icon-rounded bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-3 bs-icon lg" style={{ width: 60, height: 60 }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" className="bi bi-award-fill">
          <path d="m8 0 1.669.864 1.858.282.842 1.68 1.337 1.32L13.4 6l.306 1.854-1.337 1.32-.842 1.68-1.858.282L8 12l-1.669-.864-1.858-.282-.842-1.68-1.337-1.32L2.6 6l-.306-1.854 1.337-1.32.842-1.68L6.331.864 8 0z" />
          <path d="M4 11.794V16l4-1 4 1v-4.206l-2.018.306L8 13.126 6.018 12.1 4 11.794z" />
        </svg>
      </div>
    </a>
    <div className="px-3">
      <h4 style={{ fontWeight: 'bold' }}>About Us</h4>
    </div>
  </div>
</div>

    </div>
    <h1 style={{fontWeight: 'bold', fontSize: 56, textAlign: 'center'}}><br /><span style={{color: 'rgb(var(--color_15))', backgroundColor: 'transparent'}}>Our Partners</span><br /><br /></h1><img src="assets/img/1half.png" style={{paddingLeft: 0, marginLeft: 0, marginRight: 1, width: 1140}} /><img src="assets/img/2half.png" style={{paddingLeft: 0, marginLeft: 0, marginRight: 1, width: 1140}} />
  </div>
</div>





</>
	);
};


const teamMembers = [
  {
    name: 'Shreyash Jha',
    position: 'President',
    phone: '7042169412',
    email: 'jha.shreyash07@gmail.com',
    imageSrc: 'assets/img/Shreyash_Jha-President.png',
  },
  {
    name: 'Sahajpreet Kalra',
    position: 'Vice President',
    phone: '9599301936',
    email: 'sahaj.placementcellmlnc@gmail.com',
    imageSrc: 'assets/img/Sahajpreet_Kalra-Vice_President.png',
  },
  {
    name: 'Devang Gangwar',
    position: 'HOD (Corporate Relation)',
    phone: '7819918120',
    email: 'devanggangwar.08@gmail.com',
    imageSrc: 'assets/img/Devang_Gangwar-HOD (Corporate_Relation).png',
  },
  {
    name: 'Umang Kapoor',
    position: 'HOD (Student Relations)',
    phone: '8700568280',
    email: 'umangkapoor@gmail.com',
    imageSrc: 'assets/img/Umang_Kapoor-HOD(Student_Relations).png',
  },
  {
    name: 'Samridhi Saini',
    position: 'HOD (Communication)',
    phone: '6396418751',
    email: 'samridhisaini1@gmail.com',
    imageSrc: 'assets/img/Samridhi_Saini-HOD(Communication).png',
  },
  {
    name: 'Ayush Singh',
    position: 'HOD (Media)',
    phone: '7879887299',
    email: 'Ayush2231singh@gmail.com',
    imageSrc: 'assets/img/Ayush_Singh-HOD(Media).png',
  },
  {
    name: 'Aashish Manchanda ',
    position: 'HOD (Content)',
    phone: '8569978849',
    email: 'Aashishmanchanda679@gmail.com',
    imageSrc: 'assets/img/Aashish_Manchanda-HOD(Content).png',
  },
];

export default Main;
