import { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import styles from "./styles.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
const Signup = () => {
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [isSent, setIsSent] = useState(false);
  

  const handleChange = ({ currentTarget: input }) => {
    setData({ ...data, [input.name]: input.value });
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    
    e.preventDefault();
    setIsSending(true);
    try {
      setIsSent(true);
      const url = "https://mlndu.onrender.com/api/users";
      const { data: res } = await axios.post(url, data);
      window.location.href = "https://forms.gle/sL5ARFB755vmWub77";
      console.log(res.message);
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status <= 500
      ) {
        setError(error.response.data.message);
      }
    }
    finally {
      setIsSending(false);
    }
  };

  return (
    <div className={styles.signup_container}>
      <div className={styles.signup_form_container}>
        <div className={styles.left}>
          <h1>Welcome Back</h1>
          <Link to="/login">
            <button type="button" className={styles.white_btn}>
              Sign in
            </button>
          </Link>
        </div>
        
        <div className={styles.right}>
          <form className={styles.form_container} onSubmit={handleSubmit}>
            <h1>Create Account</h1>
            <input
              type="text"
              placeholder="First Name"
              name="firstName"
              onChange={handleChange}
              value={data.firstName}
              required
              className={styles.input}
            />
            <input
              type="text"
              placeholder="Last Name"
              name="lastName"
              onChange={handleChange}
              value={data.lastName}
              required
              className={styles.input}
            />
            <input
              type="email"
              placeholder="Email"
              name="email"
              onChange={handleChange}
              value={data.email}
              required
              className={styles.input}
            />
        <div className={styles.password_container}>
        <input
          type={showPassword ? "text" : "password"}
          placeholder="Password For example- Abc@2003"
          name="password"
          onChange={handleChange}
          value={data.password}
          required
          className={styles.input}
        />
          <FontAwesomeIcon
            icon={showPassword ? faEye : faEyeSlash}
            className={styles.toggle_password_icon}
			onClick={handleTogglePassword}
          />
        </div>
        
        
            {isSent ? (
        <div>{error && <div className={styles.error_msg}>{error}</div>}</div>
        
      ) : (
            <button type="submit" disabled={isSending} className={styles.green_btn}>
            {isSending ? 'Signing Up...' : 'Sign Up'}
          </button>
      )}
          </form>
        </div>
         
      </div>
    </div>
  );
};

export default Signup;
