/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/heading-has-content */
import styles from "./styles.module.css";

const Internships = () => {
	
	return (
		<>
    <div className={styles.s1}>
  <div className="border rounded border-0 d-flex flex-column justify-content-center align-items-center p-4 py-5" style={{background: 'linear-gradient(-0deg, rgba(0,123,255,0.2) 0%, rgba(0,123,255,0.2) 0%, rgba(0,123,255,0.2) 0%, rgba(24,86,245,0.92) 100%), url("assets/img/About-1.webp") center / cover', height: 700}}>
    <div className="row">
      <div className="col-md-10 col-xl-8 text-center d-flex d-sm-flex d-md-flex justify-content-center align-items-center mx-auto justify-content-md-start align-items-md-center justify-content-xl-center">
        <div style={{marginTop: '-36px'}}>
          <h1 className="text-uppercase fw-bold mb-3" style={{color: 'var(--bs-body-bg)', fontSize: 89, fontFamily: '"Abril Fatface", serif'}}>About us</h1>
        </div>
      </div>
    </div>
  </div>
  <div className="container py-4 py-xl-5">
    <section className="about-us">
      <div className="container">
        <div className="row padding-content">
          <div className="col-md-4 no-padding">
            <div id="wowslider-container1">
              <div className="ws_images">
                <ul>
                  <li style={{marginBottom: 1}}><img data-aos="fade-up" id="wows1_0" src="assets/img/About.webp" width={605} height={340} style={{marginBottom: 3, paddingTop: 0, paddingLeft: 0, marginLeft: 6, paddingRight: 251, height: '364.175px', width: 601, marginRight: '-252px', marginTop: 143, paddingBottom: 141}} /></li>
                  <li><img id="wows1_1" src="3Hb0RH_s6s8.jpg" /></li>
                  <li><img id="wows1_2" src="6NMPUFefwgM.jpg" /></li>
                  <li><img id="wows1_3" src="8sl6Q86gsfM.jpg" /></li>
                  <li><img id="wows1_4" src="wNvJKN1zPtE.jpg" /><div>Custom Code</div></li>
                </ul>
              </div>
              <div className="ws_script" href="http://wowslider.net" style={{position: 'absolute', left: '-99%'}}><a href="http://wowslider.net">javascript image slider</a><strong>Bold</strong></div>
              <div className="ws_shadow" />
            </div>
          </div>
          <div className="col-md-8 abt-right" style={{background: 'rgb(255,255,255)'}}>
            <h1 className="text-center" style={{color: 'var(--bs-dark)'}}><strong>ABOUT US</strong></h1>
            <p style={{color: 'var(--bs-dark)', fontSize: 18}}>Motilal Nehru College, part of the University of Delhi, stands as one of the largest constituent colleges. It honors the memory of Pandit Motilal Nehru, a prominent leader and freedom fighter. The college was established in 1964 under the Delhi Administration as an extension of the university. Since then, it has garnered widespread acclaim and recognition. Hosting more than 140 faculty members and accommodating 3,000 students from various corners of India, it has emerged as a significant educational hub. With steadfast support from the college principal, management, teachers, esteemed alumni, as well as robust infrastructure and cutting-edge technology on campus, we continue to uphold the tradition of strengthening our connections with corporate entities and fostering enduring relationships with first-time&nbsp;recruiters.</p>
          </div>
        </div>
      </div>
    </section>
    <section id="about">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="timeline-heading">
              <h1 className="subheading" style={{fontWeight: 'bold'}}><br /><span style={{color: 'rgb(var(--color_15))', backgroundColor: 'transparent'}}>Message from the Principal</span><br /><br /></h1><img className="rounded-circle img-fluid" src="assets/img/Principal.png" style={{width: 180, height: 180, marginBottom: 0, textAlign: 'center'}} width={180} height={180} />
              <h1 style={{fontWeight: 'bold', fontSize: 20, marginTop: 6}}>Dr. Yogeshwar Sharma</h1>
            </div>
            <div className="timeline-heading">
              <h1 className="subheading" style={{ fontSize: 18, marginTop: 0}}><br /><span style={{fontWeight: 'normal !important', color: 'rgb(33, 37, 41)'}}>Motilal Nehru College ranks among the foremost coeducational institutions nationwide. We provide a diverse range of undergraduate courses covering the three principal streams of Arts, Science, and Commerce.</span><br /><br /><span style={{fontWeight: 'normal !important', color: 'rgb(33, 37, 41)'}}>The college serves as an excellent platform and supports students in unlocking their complete capabilities. Our college's Placement Cell actively collaborates with diverse recruiters, leading to numerous graduates securing positions in top-tier companies across various sectors each year. I extend a warm invitation to recruiters to visit our campus for their human resource needs, with the hope that both their expectations and our students' requirements will be satisfactorily&nbsp;fulfilled.</span><br /><br /></h1>
              {/* <h1 className="subheading" style={{fontWeight: 'bold'}}><br /><span style={{color: 'rgb(var(--color_15))', backgroundColor: 'transparent'}}>Message from the Convenor</span><br /><br /></h1><img className="rounded-circle img-fluid" src="assets/img/convenor.webp" style={{width: 180, height: 180}} width={200} height={200} /> */}
              {/* <h1 style={{fontWeight: 'bold', fontSize: 20, textAlign: 'left', marginTop: 8}}>Ms. Shipra Gupta</h1>
              <h1 className="subheading" style={{fontSize: 18, marginBottom: 89, marginTop: '-3px'}}><br /><span style={{fontWeight: 'normal !important', color: 'rgb(33, 37, 41)'}}>Motilal Nehru College, part of the University of Delhi, stands as one of the largest constituent colleges. It honors the memory of Pandit Motilal Nehru, a prominent leader and freedom fighter. The college was established in 1964 under the Delhi Administration as an extension of the university. Since then, it has garnered widespread acclaim and recognition. Hosting more than 140 faculty members and accommodating 3,000 students from various corners of India, it has emerged as a significant educational hub. With steadfast support from the college principal, management, teachers, esteemed alumni, as well as robust infrastructure and cutting-edge technology on campus, we continue to uphold the tradition of strengthening our connections with corporate entities and fostering enduring relationships with first-time&nbsp;recruiters.</span><br /><br /></h1>
              <p style={{color: 'var(--bs-dark)', fontSize: 14, paddingRight: 57, marginLeft: 210, marginBottom: 19, marginRight: 45, marginTop: '-159px'}} /> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <div className="container">
    <h1 style={{fontWeight: 'bold', fontSize: 54, textShadow: '3px 0px', textAlign: 'center'}}><br /><span style={{color: 'rgb(var(--color_15))', backgroundColor: 'transparent'}}>Our Placement Partners</span><br /><br /></h1><img src="assets/img/Placement%20partner.png" />
  </div>
  <div className="container">
    <h1 style={{fontWeight: 'bold', fontSize: 54, textShadow: '3px 0px', textAlign: 'center'}}><br /><span style={{color: 'rgb(var(--color_15))', backgroundColor: 'transparent'}}>Our Internship Partners</span><br /><br /></h1><img src="assets/img/Internship%20partner.png" />
  </div>
</div>




        </>
	);
};

export default Internships;
