/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import '../css/Footer.css';
const Footer = () => {
  return (
    <footer id="myFooter" style={{bsBodyBg: 'var(--swiper-theme-color)', background: 'rgb(18,18,55)', paddingTop: 0}}>
    <div className="container-fluid">
      <div className="row text-center">
        <div className="col-12 col-sm-6 col-md-3" style={{paddingRight: 0, paddingLeft: 0, marginLeft: 0, paddingTop: 0, marginTop: 93}}>
          <h1 className="logo" style={{marginTop: 30}} /><img className="img-fluid" src="assets/img/logo.jpg" width={226} height={226} style={{width: '274.662px', marginBottom: 0, paddingRight: 0, marginLeft: 1, marginRight: 4, height: '107.55px'}} /><a className="linkedin" href="https://www.linkedin.com/company/the-placement-cell-mlnc/" style={{paddingLeft: 0}}><i className="fa fa-linkedin-square" style={{fontSize: 32, bsBodyBg: 'var(--bs-card-bg)', marginTop: 0, marginRight: 2, marginLeft: 83}} /></a><a className="google" href="https://instagram.com/placementcell_mlnc?igshid=MzRlODBiNWFlZA=="><i className="fa fa-instagram" style={{fontSize: 32, bsBodyBg: 'var(--bs-dark)', bsBodyColor: 'var(--bs-dark)', borderColor: 'var(--bs-dark)', color: 'var(--bs-white)', background: 'rgba(0,0,0,0)', marginRight: 24, marginLeft: 41}} /></a>
        </div>
        <div className="col-12 col-sm-6 col-md-2 col-xxl-2" style={{marginTop: 118, marginLeft: 0, marginRight:-100}}>
          <h5 style={{fontSize: 24}}>Quick Links</h5>
          <ul>
            <li><a href="/">Home</a></li>
            <li><a href="/about">About Us</a></li>
            <li><a href="/signup">Sign Up</a></li>
            <li />
          </ul>
        </div>
        <div className="col-12 col-sm-6 col-md-2 col-xxl-2">
          <ul>
            <li />
            <li />
            <li />
          </ul>
        </div>
        <div className="col-sm-6 col-md-2 col-lg-4 col-xl-4" style={{marginTop: 110}}>
          <h5 className="text-center" style={{fontSize: 24}}>Contact Us</h5>
          <p className="text-start">E-mail: placementcell@mln.du.ac.in<br />Call: +91 7042169412 ; +91 9599301936<br />Address: Benito Juarez Marg, South Campus, South Moti Bagh, New Delhi,&nbsp;Delhi&nbsp;110021</p>
          <ul>
            <li />
          </ul>
        </div>
      </div>
      <hr />
      <div className="fix col-sm-6 col-md-2 col-lg-4 col-xl-4" style={{marginTop: 0}}>
          <p className="text-start">Copyright@2023 PlacementCell Motilal Nehru College Delhi University <br/> <a href="https://akashmandal.online/">Design & Developed By - AKASH MANDAL</a></p>
          
          <ul>
            <li />
          </ul>
        </div>
    </div>
    <footer className="text-white bg-dark">
      <div className="footer-2" style={{paddingTop: 0, background: 'rgb(18,18,55)'}} />
    </footer>
  </footer>
  );
};

export default Footer;
