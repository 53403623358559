import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styles from './styles.module.css';
import { useNavigate } from 'react-router-dom';
const DeleteItems = () => {
  const [placements, setPlacements] = useState([]);
  const [internships, setInternships] = useState([]);
  const [news, setNews] = useState([]);

  useEffect(() => {
    const fetchPlacements = async () => {
      try {
        const response = await axios.get('https://mlndu.onrender.com/api/placements');
        setPlacements(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchInternships = async () => {
      try {
        const response = await axios.get('https://mlndu.onrender.com/api/internships');
        setInternships(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchNews = async () => {
      try {
        const response = await axios.get('https://mlndu.onrender.com/api/news');
        setNews(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchPlacements();
    fetchInternships();
    fetchNews();
  }, []);

  const handleDeletePlacement = async (id) => {
    try {
      await axios.delete(`https://mlndu.onrender.com/api/placements/${id}`);
      // Update the list of placements after successful deletion
      setPlacements((prevPlacements) => prevPlacements.filter((placement) => placement._id !== id));
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteInternship = async (id) => {
    try {
      await axios.delete(`https://mlndu.onrender.com/api/internships/${id}`);
      // Update the list of internships after successful deletion
      setInternships((prevInternships) => prevInternships.filter((internship) => internship._id !== id));
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteNews = async (id) => {
    try {
      await axios.delete(`https://mlndu.onrender.com/api/news/${id}`);
      // Update the list of internships after successful deletion
      setInternships((prevNews) => prevNews.filter((news) => news._id !== id));
    } catch (error) {
      console.error(error);
    }
  };

  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Check if the user is an admin
    const isAdmin = localStorage.getItem("isAdmin") === "true";
    setIsAdmin(isAdmin);

    if (!isAdmin) {
      // If not an admin, navigate to the home page
      navigate('/');
    }
  }, [isAdmin, navigate]);

  return (
    <div className={styles.delete_container}>
      <h2>Delete Placements/Internships/News</h2>
      <div className={styles.placements_container}>
        <h3>Placements</h3>
        {placements.map((placement) => (
          <div key={placement._id} className={styles.placement_delete_card}>
            <h3>{placement.company}</h3>
        
            <p>Role: {placement.role}</p>
            <button onClick={() => handleDeletePlacement(placement._id)} className={styles.delete_button}>
              Delete
            </button>
          </div>
        ))}
      </div>
      <div className={styles.internships_container}>
        <h3>Internships</h3>
        {internships.map((internship) => (
          <div key={internship._id} className={styles.internship_delete_card}>
            <h3>{internship.company}</h3>
            <p>Role: {internship.role}</p>
            <button onClick={() => handleDeleteInternship(internship._id)} className={styles.delete_button}>
              Delete
            </button>
          </div>
        ))}
      </div>
      <div className={styles.internships_container}>
        <h3>News</h3>
        {news.map((news) => (
          <div key={news._id} className={styles.internship_delete_card}>
           
            <h3>Title: {news.title}</h3>
      
            <button onClick={() => handleDeleteNews(news._id)} className={styles.delete_button}>
              Delete
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DeleteItems;
