import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './styles.module.css';
import { useNavigate } from 'react-router-dom';
const PlacementForm = () => {
  const [company, setCompany] = useState('');
  const [role, setRole] = useState('');
  const [description, setDescription] = useState('');
  const [view, setview] = useState('');
  const [applyLink, setApplyLink] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [isSent, setIsSent] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSending(true);

    try {
      await axios.post('https://mlndu.onrender.com/api/placements', {
        company,
        role,
        description,
        view,
        applyLink,
        imageUrl,
      });
      setIsSent(true);
      // Clear form fields after successful submission
      setCompany('');
      setRole('');
      setDescription('');
      setview('');
      setApplyLink('');
      setImageUrl('');
    } catch (error) {
      console.error(error);
    }finally {
      setIsSending(false);
    }
  };

  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Check if the user is an admin
    const isAdmin = localStorage.getItem("isAdmin") === "true";
    setIsAdmin(isAdmin);

    if (!isAdmin) {
      // If not an admin, navigate to the home page
      navigate('/');
    }
  }, [isAdmin, navigate]);

  return (
    <div className={styles.placement_form_container}>
      <h2>Submit a Placement</h2>
      {isSent ? (
        <p className={styles.success_message}>Placements submitted successfully!</p>
      ) : (
      <form className={styles.form_container} onSubmit={handleSubmit}>
        <h2>Submit Placement</h2>
        <input
          type="text"
          placeholder="Company"
          value={company}
          onChange={(e) => setCompany(e.target.value)}
          required
        />
        <input
          type="text"
          placeholder="Role"
          value={role}
          onChange={(e) => setRole(e.target.value)}
          required
        />
        <textarea
          placeholder="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
        />
        <input
          type="text"
          placeholder="JD link"
          value={view}
          onChange={(e) => setview(e.target.value)}
          required
        />
        <input
          type="text"
          placeholder="Apply Link"
          value={applyLink}
          onChange={(e) => setApplyLink(e.target.value)}
          required
        />
        <input
          type="text"
          placeholder="Image URL"
          value={imageUrl}
          onChange={(e) => setImageUrl(e.target.value)}
          required
        />
        <button type="submit" disabled={isSending}>
        {isSending ? 'Submitting...' : 'Submit Placement'}
        </button>
      </form>
      )}
    </div>
  );
};

export default PlacementForm;
