// UserManagement.jsx

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './styles.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch the list of users from the backend when the component mounts
    axios
      .get('https://mlndu.onrender.com/api/users')
      .then((response) => {
        setUsers(response.data);
        setFilteredUsers(response.data);
      })
      .catch((error) => {
        console.error('Error fetching users:', error);
      });
  }, []);

  useEffect(() => {
    // Check if the user is an admin
    const isAdmin = localStorage.getItem('isAdmin') === 'true';
    setIsAdmin(isAdmin);

    if (!isAdmin) {
      // If not an admin, navigate to the home page
      navigate('/');
    }
  }, [isAdmin, navigate]);

  // Update the filteredUsers when the search input changes
  useEffect(() => {
    const filtered = users.filter(
      (user) =>
        `${user.firstName} ${user.lastName}`.toLowerCase().includes(search.toLowerCase()) ||
        user.email.toLowerCase().includes(search.toLowerCase())
    );
    setFilteredUsers(filtered);
  }, [search, users]);

  const deleteUser = (userId) => {
    // Show confirmation popup before deleting
    setShowConfirmation(true);
    setUserToDelete(userId);
  };

  const confirmDelete = () => {
    // Send a DELETE request to the backend to delete the user by ID
    axios
      .delete(`https://mlndu.onrender.com/api/users/${userToDelete}`)
      .then((response) => {
        // Remove the deleted user from the local state
        setUsers(users.filter((user) => user._id !== userToDelete));
        // Close the confirmation popup
        setShowConfirmation(false);
        setUserToDelete(null);
      })
      .catch((error) => {
        console.error('Error deleting user:', error);
        // Close the confirmation popup in case of an error
        setShowConfirmation(false);
        setUserToDelete(null);
      });
  };

  const cancelDelete = () => {
    // Close the confirmation popup
    setShowConfirmation(false);
    setUserToDelete(null);
  };

  return (
    <div className={styles.user_approval_container}>
      {/* Search input */}
      <input
        className={styles.search}
        type="text"
        placeholder="Search by name or email"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />

      {/* Confirmation popup */}
      {showConfirmation && (
        <div className={styles.confirmationPopup}>
          <p>Are you sure you want to delete this user?</p>
          <button onClick={confirmDelete}>Yes</button>
          <br/>
          <button onClick={cancelDelete}>No</button>
        </div>
      )}

      <table>
        <thead>
          <tr>
            <th>First Name</th>
            <th>Email</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {filteredUsers.map((user) => (
            <tr key={user._id}>
              <td>
                <strong>
                  {user.firstName} {user.lastName}
                </strong>
              </td>
              <td>{user.email}</td>
              <td>
                <button className={styles.delete_button} onClick={() => deleteUser(user._id)}>
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserManagement;
