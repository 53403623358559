/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/style-prop-object */
import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import '../css/Navbar.css';
// import logoImage from '../assets/logo.jpg';

const Navbar = ({ isAuthenticated,isAdmin, handleLogout }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  
  
  // const closeSidebar = () => {
  //   setMenuOpen(false);
  // };

  
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    // Add event listener to close the menu when a link inside the menu is clicked
    const handleLinkClick = () => {
      setMenuOpen(false);
    };

    const links = document.querySelectorAll('.nav-links a, .nav-links button');
    links.forEach((link) => {
      link.addEventListener('click', handleLinkClick);
    });

    return () => {
      // Clean up the event listener on unmount
      links.forEach((link) => {
        link.removeEventListener('click', handleLinkClick);
      });
    };
  }, []);

  return (
    <nav className="navbar navbar-expand-md navigation-clean navbar-inverse navbar-fixed-top navbar-light">
      <div className="logo">
        <Link to="/"><img className="img-fluid" src="assets/img/logo.jpg" width={226} height={226} style={{width: '274.662px', marginBottom: 0, paddingRight: 0, marginLeft: 1, marginRight: 4, height: '107.55px'}} /></Link>
      </div>
      <div className={`nav-links sidebar ${menuOpen ? 'open' : ''}`}>
        <ul>
          <strong><li><NavLink exact to="/">HOME</NavLink></li></strong>
          <strong><li><NavLink to="/about">ABOUT</NavLink></li></strong>
          <strong><li><NavLink to="/contact">CONTACT</NavLink></li></strong>
          
          {isAuthenticated && ( // Only show these links if authenticated
            <>
            
              <strong><li className="dropdown">
                <center>ONGOING OPPORTUNITIES</center>
                <ul className="dropdown-content">
                  <strong><li><NavLink to="/placements">Placements</NavLink></li></strong>
                  <strong><li><NavLink to="/internships">Internships</NavLink></li></strong>
                </ul>
              </li></strong>
              
            </>
          )}
          <strong><li><NavLink to="/news">NEWS&SEMINARS</NavLink></li></strong>
          {isAdmin && isAuthenticated && (
            <strong><li className="dropdown"><strong>ADMIN-DASHBOARD</strong>
            <ul className="dropdown-content">
                  <strong><li><NavLink to="/admin-message">ContactMessages</NavLink></li></strong>
                  <strong><li><NavLink to="/user-approval">UserApproval</NavLink></li></strong>
                  <strong><li><NavLink to="/internship-from">InternshipForm</NavLink></li></strong>
                  <strong><li><NavLink to="/placement-from">PlacementForm</NavLink></li></strong>
                  <strong><li><NavLink to="/news-From">NewsForm</NavLink></li></strong>
                  <strong><li><NavLink to="/user-management">UserManagement</NavLink></li></strong>
                  <strong><li><NavLink to="/manage">MANAGE</NavLink></li></strong>
                </ul>
            </li></strong>
          )}
          <li>
            {isAuthenticated ? (
              <button onClick={handleLogout} className="white_btn">
                LOGOUT
              </button>
            ) : (
              <Link to="/signup" className="white_btn">
                REGISTER
              </Link>
            )}
          </li>
        </ul>
        
{/* <button className="menu-icon close-btn" onClick={closeSidebar}>
  &times;
</button> */}
      </div>
      <div className="menu-icon" onClick={toggleMenu}>
        ☰
      </div>
      



    </nav>
  );
};

export default Navbar;
