import React, { useEffect, useState } from "react";
import axios from 'axios';
import styles from "./styles.module.css";
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const AdminDashboard = () => {
        const [contactMessages, setContactMessages] = useState([]);
      
        const fetchContactMessages = async () => {
          try {
            const response = await axios.get('https://mlndu.onrender.com/api/contact');
            setContactMessages(response.data);
          } catch (error) {
            console.error(error);
          }
        };
      
        const handleDeleteMessage = async (id) => {
          try {
            await axios.delete(`https://mlndu.onrender.com/api/contact/${id}`);
            fetchContactMessages(); // Refresh the messages after successful deletion
          } catch (error) {
            console.error(error);
          }
        };
      
        useEffect(() => {
          fetchContactMessages();
        }, []);
  // Use local state to manage the admin status
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Check if the user is an admin
    const isAdmin = localStorage.getItem("isAdmin") === "true";
    setIsAdmin(isAdmin);

    if (!isAdmin) {
      // If not an admin, navigate to the home page
      navigate('/');
    }
  }, [isAdmin, navigate]);

  const handleReplyMessage = (email) => {
    window.location.href = `mailto:${email}`;
  };
  // If the user is an admin, render the admin dashboard content
  return (
    <div className={styles.contact_messages}>
      <h2>Contact Messages</h2>
      {contactMessages.map((message) => (
        <div key={message._id} className={styles.message}>
          <p>
            <strong>Name:</strong> {message.name}
          </p>
          <p>
            <strong>Email:</strong> {message.email}
          </p>
          <p>
            <strong>Phone:</strong> {message.phone}
          </p>
          <p>
            <strong>Message:</strong> {message.message}
          </p>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <button
              onClick={() => handleReplyMessage(message.email)}
              style={{ backgroundColor: 'blue', marginRight: '5px', width: '100px' }}
            >
              Reply
            </button>
            <button
              onClick={() => handleDeleteMessage(message._id)}
              style={{ backgroundColor: 'red',width: '100px' }}
            >
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};



export default AdminDashboard;

