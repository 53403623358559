import React, { useEffect, useState } from "react";
import axios from 'axios';
import styles from "./styles.module.css";

const InternshipList = () => {
  const [internships, setInternships] = useState([]);

  const fetchInternships = async () => {
    try {
      const response = await axios.get('https://mlndu.onrender.com/api/internships');
      setInternships(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchInternships();
  }, []);

  // Add code to handle deleting internships

  return (
    <div className={styles.internship_list}>
      <strong><h2>Ongoing Internships</h2></strong>
      {internships.map((internship) => (
        <div key={internship._id} className={styles.internship}>
          <img src={internship.imageUrl} alt="Company Logo" />
          <h3>{internship.company} - {internship.role}</h3>
          <p>{internship.description}</p>
          <hr/>
          <a href={internship.applyLink} target="_blank" rel="noopener noreferrer">Apply</a>
         
          <a href={internship.view} target="_blank" rel="noopener noreferrer">View</a>
          
          {/* Add delete button and functionality */}
        </div>
      ))}
    </div>
  );
};

export default InternshipList;
