import React, { useState, useEffect } from "react";
import axios from "axios";
import styles from "./styles.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const UserApproval = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [userActionId, setUserActionId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = "https://mlndu.onrender.com/api/users";
        const { data } = await axios.get(url);
        setUsers(data);
        setFilteredUsers(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching users", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    // Check if the user is an admin
    const isAdmin = localStorage.getItem("isAdmin") === "true";
    setIsAdmin(isAdmin);

    if (!isAdmin) {
      // If not an admin, navigate to the home page
      navigate("/");
    }
  }, [isAdmin, navigate]);

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearch(searchTerm);

    const filtered = users.filter(
      (user) =>
        user.firstName.toLowerCase().includes(searchTerm) ||
        user.lastName.toLowerCase().includes(searchTerm) ||
        user.email.toLowerCase().includes(searchTerm)
    );
    setFilteredUsers(filtered);
  };

  const openApproveModal = (userId) => {
    setShowApproveModal(true);
    setUserActionId(userId);
  };

  const closeApproveModal = () => {
    setShowApproveModal(false);
    setUserActionId(null);
  };

  const approveUser = async () => {
    try {
      const url = `https://mlndu.onrender.com/api/users/approve/${userActionId}`;
      await axios.put(url);
      const updatedUsers = users.map((user) => {
        if (user._id === userActionId) {
          user.approved = true;
        }
        return user;
      });
      setUsers(updatedUsers);
    } catch (error) {
      console.error("Error approving user", error);
    } finally {
      closeApproveModal();
    }
  };

  const openDeleteModal = (userId) => {
    setShowDeleteModal(true);
    setUserActionId(userId);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
    setUserActionId(null);
  };

  const deleteUser = async () => {
    try {
      await axios.delete(`https://mlndu.onrender.com/api/users/${userActionId}`);
      setUsers(users.filter((user) => user._id !== userActionId));
    } catch (error) {
      console.error("Error deleting user", error);
    } finally {
      closeDeleteModal();
    }
  };

  return (
    <div className={styles.user_approval_container}>
      <input
        className={styles.search}
        type="text"
        placeholder="Search by name or email"
        value={search}
        onChange={handleSearch}
      />

      {loading ? (
        <p>Loading users...</p>
      ) : (
        <table>
          <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Approved</th>
              <th>Action</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map((user) => (
              <tr key={user._id}>
                <td>{user.firstName}</td>
                <td>{user.lastName}</td>
                <td>{user.email}</td>
                <td>{user.approved ? "Yes" : "No"}</td>
                <td>
                  {!user.approved && (
                    <button
                      className={styles.approve_button}
                      onClick={() => openApproveModal(user._id)}
                    >
                     Approve
                    </button>
                  )}
                </td>
                <td>
                  {!user.approved && (
                    <button
                      className={styles.delete_button}
                      onClick={() => openDeleteModal(user._id)}
                    >
                      <FontAwesomeIcon icon={faTrash} /> 
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {/* Approve User Modal */}
      {showApproveModal && (
        <div className={styles.modal}>
          <p>Are you sure you want to approve this user?</p>
          <button onClick={approveUser}>Yes</button>
          <br/>
          <button onClick={closeApproveModal}>No</button>
        </div>
      )}

      {/* Delete User Modal */}
      {showDeleteModal && (
        <div className={styles.modal}>
          <p>Are you sure you want to delete this user?</p>
          <button onClick={deleteUser}>Yes</button>
          <br/>
          <button onClick={closeDeleteModal}>No</button>
        </div>
      )}
    </div>
  );
};

export default UserApproval;
