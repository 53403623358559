import React, { useState , useEffect} from 'react';
import axios from 'axios';
import styles from "./styles.module.css";
import { useNavigate } from 'react-router-dom';
const NewsForm = () => {
 
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [view, setview] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [isSent, setIsSent] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSending(true);

    try {
      await axios.post('https://mlndu.onrender.com/api/news', { title, description, view });
      setIsSent(true);
      // Clear form fields after successful submission
     
      setTitle('');
      setDescription('');
      
      setview('');
      
    } catch (error) {
      console.error(error);
    } finally {
      setIsSending(false);
    }

  };
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Check if the user is an admin
    const isAdmin = localStorage.getItem("isAdmin") === "true";
    setIsAdmin(isAdmin);

    if (!isAdmin) {
      // If not an admin, navigate to the home page
      navigate('/');
    }
  }, [isAdmin, navigate]);

  return (
    <div className={styles.internship_form}>
      <h2>Submit an News</h2>
      {isSent ? (
        <p className={styles.success_message}>News submitted successfully!</p>
      ) : (
        <form className={styles.form_container} onSubmit={handleSubmit}>
          
          <div className={styles.form_group}>
            <label htmlFor="role">Role:</label>
            <input
              type="text"
              id="role"
              placeholder="Role Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </div>
          <div className={styles.form_group}>
            <label htmlFor="description">Description:</label>
            <textarea
              id="description"
              placeholder="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </div>
          
          <div className={styles.form_group}>
            <label htmlFor="view">View Link:</label>
            <input
              type="text"
              id="view"
              placeholder="View Link"
              value={view}
              onChange={(e) => setview(e.target.value)}
              required
            />
          </div>
         
          <button type="submit" disabled={isSending}>
            {isSending ? 'Submitting...' : 'Submit News'}
          </button>
        </form>
      )}
    </div>
  );
};

export default NewsForm;
