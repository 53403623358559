import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './styles.module.css';

const PlacementList = () => {
  const [placements, setPlacements] = useState([]);

  useEffect(() => {
    const fetchPlacements = async () => {
      try {
        const response = await axios.get('https://mlndu.onrender.com/api/placements');
        setPlacements(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchPlacements();
  }, []);

  return (
    <div className={styles.placement_list_container}>
      <strong><h2>Ongoing Placements</h2></strong>
      {placements.map((placement) => (
        <div key={placement._id} className={styles.placement_card}>
          <h2>{placement.company}</h2>
          <img src={placement.imageUrl} alt={placement.company} />
          <h3>{placement.company} - {placement.role}</h3>
          {/* <strong><p>Role: {placement.role}</p></strong> */}
          <p>{placement.description}</p>
          
          <hr/>
          <a href={placement.view} target="_blank" rel="noopener noreferrer">
            Job Description
          </a>
          <a href={placement.applyLink} target="_blank" rel="noopener noreferrer">
            Apply Here
          </a>
        </div>
      ))}
    </div>
  );
};

export default PlacementList;
