import React, { useEffect, useState } from "react";
import axios from 'axios';
import styles from "./styles.module.css";

const NewsList = () => {
  const [news, setNews] = useState([]);

  const fetchNews = async () => {
    try {
      const response = await axios.get('https://mlndu.onrender.com/api/news');
      setNews(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchNews();
  }, []);

  // Add code to handle deleting internships

  return (
    <div className={styles.internship_list}>
      <strong><h2>Ongoing News</h2></strong>
      {news.map((news) => (
        <div key={news._id} className={styles.internship}>
          <h3>{news.title}</h3>
          <p>{news.description}</p>
          <hr/>
        
          <a href={news.view} target="_blank" rel="noopener noreferrer">View</a>
          
          {/* Add delete button and functionality */}
        </div>
      ))}
    </div>
  );
};

export default NewsList;
